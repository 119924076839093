/* index.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100%; /* Корректно распределяем высоту */
  width: 100%;
  font-family: 'Rubik', sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: #f5f5f5;
}
