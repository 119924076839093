/* Main.module.css */
.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 40px;
  background: linear-gradient(135deg, #e0e0e0, #ffffff);
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.textContent {
  max-width: 50%;
}

.textContent h1 {
  font-size: 64px;
  color: #343a40;
  margin-bottom: 20px;
}

.textContent p {
  font-size: 20px;
  color: #6c757d;
  margin-bottom: 40px;
  line-height: 1.6;
}

.main img {
  width: 45%;
  max-width: 600px;
  border-radius: 10px;
}

/* Адаптивные стили для планшетов */
@media (max-width: 1024px) {
  .main {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .textContent {
    max-width: 100%;
  }

  .main img {
    width: 80%;
    margin-top: 20px;
  }

  .textContent h1 {
    font-size: 48px; /* Уменьшение размера заголовка */
  }

  .textContent p {
    font-size: 18px;
  }
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 768px) {
  .main img {
    width: 100%;
  }

  .textContent h1 {
    font-size: 36px; /* Еще меньше заголовок для мобильных */
    text-align: center; /* Центрируем текст на мобильных */
  }

  .textContent p {
    font-size: 16px;
    text-align: center; /* Центрируем абзац на мобильных */
  }
}

/* Стили для самых маленьких экранов */
@media (max-width: 480px) {
  .textContent h1 {
    font-size: 28px; /* Минимальный размер заголовка */
  }

  .textContent p {
    font-size: 14px;
  }
}
