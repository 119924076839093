/* Footer.module.css */
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  background-color: #e9ecef;
  color: #343a40;
  border-radius: 8px;
  margin-top: 20px;
  gap: 40px;
  flex-wrap: wrap; /* Для адаптации на маленьких экранах */
}

.footer img {
  height: 70px;
  object-fit: contain;
  margin-left: 50px; /* Отступ слева от логотипа */
}

.footerSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

/* Отступ справа у секции документов */
.documents {
  margin-right: 50px;
}

.footerSection h3 {
  margin-bottom: 8px;
  font-size: 18px;
  color: #343a40;
}

.footerSection a {
  color: #343a40;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 14px;
}

.footerSection a:hover {
  color: #9FD181; /* Цвет ссылок при наведении */
}

.footerSection p {
  margin: 0;
  font-size: 14px;
  color: #343a40;
}

.copyright {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold; /* Жирный копирайт */
}

/* Адаптивные стили */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer img {
    margin-left: 0; /* Убираем отступ у логотипа на мобильных */
  }

  .documents {
    margin-right: 0; /* Убираем отступ у документов на мобильных */
  }

  .footerSection {
    width: 100%; /* Секции занимают всю ширину */
    align-items: center; /* Центрируем секции на мобильных устройствах */
    text-align: center;
  }
}
