/* App.module.css */
.container {
  width: 100%;
  max-width: 1440px; /* Увеличили ширину контейнера */
  margin: 0 auto;
  padding: 20px;
}

button {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

input, textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

:global(.fadeIn) {
  opacity: 0;
  animation: fadeIn 2.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
