.link {
  text-decoration: none;
  color: inherit;
  position: relative;
}

.link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #6c757d;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.formWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
}

h2 {
  font-size: 32px;
  margin-bottom: 12px;
  color: #343a40;
}

.subtitle {
  font-size: 20px;
  color: #6c757d;
  margin-bottom: 20px;
  line-height: 1.5;
}

input {
  margin-bottom: 16px;
  padding: 14px;
  font-size: 18px;
  border: 3px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus {
  border-color: #9fd181;
  box-shadow: 0 0 5px rgba(159, 209, 129, 0.5);
}

.errorInput {
  border: 2px solid red;
}

.agreement {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.agreement input {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.submitButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #9fd181;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.submitButton:hover {
  background-color: rgba(159, 209, 129, 0.5);
}

.submitButton:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid white;
  border-top: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.status {
  margin-top: 16px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  animation: fadeInOut 5s linear;
  opacity: 0;
}

.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Адаптивные стили для планшетов и смартфонов */
@media (max-width: 768px) {
  h2 {
    font-size: 28px;
  }

  .subtitle {
    font-size: 18px;
  }

  input {
    padding: 12px;
    font-size: 16px;
  }

  .submitButton {
    font-size: 18px;
    padding: 14px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
  }

  input {
    padding: 10px;
    font-size: 15px;
  }

  .agreement {
    flex-direction: row;
    align-items: center;
  }

  .agreement input {
    margin-right: 10px; /* Оставляем отступ между чекбоксом и текстом */
  }

  .agreement label {
    text-align: left; /* Убедимся, что текст выровнен */
  }

  .submitButton {
    font-size: 16px;
    padding: 12px;
  }
}
